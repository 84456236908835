"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isImageMarketID = isImageMarketID;
exports.isImageMarketRef = isImageMarketRef;
function isImageMarketID(value) {
    return 'market' in value && 'id' in value;
}
function isImageMarketRef(value) {
    return 'market' in value && 'ref' in value;
}
